import {BooleanState} from '@utility/domain/boolean.state';
import * as _Interceptors from './interceptors';
import {Module} from '@utility/module';

export const Utility = {
  BooleanState,
  Interceptors: _Interceptors,
};

export default Module;
