import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'utility-avatar-component',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: ` Avatar `,
})
export class AvatarComponent {
}
